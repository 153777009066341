import React from 'react'
import Layout from '../components/layout'
import {
	Container,
	Sidebar,
	Body,
} from '../components/layouts'

import theme from '../theme/content'


const NotFoundPage = () => (
	<Layout>
		<Container>
			<Sidebar content={theme.adminSidebar} />
			<Body>
				<h1>NOT FOUND</h1>
				<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
			</Body>
		</Container>
	</Layout>
)

export default NotFoundPage
